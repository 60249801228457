:root {
  --green-1: #7ba779;
  --green-2: #b3eeb1;
  --lightblue: #87ceeb;
  --darkblue: #261f8b;
  --background-color: #fff;
  --background-color-dark-theme: #25253f;
  --text-color: #000000;
  --text-color-dark-theme: #fff;
  --danger-color: #fc4848;
  --popup-background: #5c8596;
}

/* @font-face {
  font-family: berlin;
  src: url("../src/assets/font/Berlin\ Sans\ FB\ Regular.ttf");
} */

@font-face {
  font-family: Oswald;
  src: url("../src/assets/font/Oswald-VariableFont_wght.ttf");
}

@font-face {
  font-family: Readex;
  src: url("../src/assets/font/ReadexPro-VariableFont_HEXP\,wght.ttf");
}

body {
  margin: 0;
  font-family: Readex;
  color: var(--text-color);
}

h2 {
  color: var(--text-color);
}

body.light {
  color: var(--text-color);
  background-color: var(--background-color);
}

body.light p,
body.light span,
body.light #deleteProfile,
body.light h3 {
  color: var(--text-color);
}

body.light button p {
  color: var(--text-color-dark-theme);
}

body.light hr {
  background-color: var(--text-color);
}

/* body.light p, body.light span {
  color: var(--text-color)
} */

body.dark .settings .darkModeToggleContainer .slider {
  background-color: var(--darkblue);
}

body.dark {
  background-color: var(--background-color-dark-theme);
}

body.dark a,
body.dark h1,
body.dark label,
body.dark .listLink,
body.dark .frontpage .list p,
body.dark .frontpage h3,
body.dark .frontpage .profileName,
body.dark .backSection p,
body.dark #privacyPolicyDiv *,
/* body.dark span, */
body.dark .settings .darkModeToggleContainer .slider {
  color: var(--text-color-dark-theme);
}

body.dark h1,
body.dark h3 {
  font-weight: 500;
}

body.dark hr {
  height: 0.15rem;
  background-color: var(--background-color) !important;
  border: none;
}

body .button p {
  color: var(--text-color-dark-theme);
  font-family: Oswald;
  text-transform: uppercase;
}

body.dark .frontpage .icon,
body.dark .firstSection .icon,
body.dark .list .icon {
  filter: invert(99%) sepia(3%) saturate(112%) hue-rotate(266deg)
    brightness(118%) contrast(87%);
}

body.dark .doneText {
  color: var(--text-color-dark-theme);
}

body.dark .notification {
  background-color: var(--lightblue);
}

body.dark .popup {
  background-color: var(--lightblue);
  border: #ffffff 3px solid;
}

body.dark .addSection {
  background-color: var(--lightblue);
}

body.dark .addNewListIcon {
  filter: unset;
}

body.dark .joinListResponse p {
  color: var(--text-color-dark-theme);
}

body.dark .joinListResponse button {
  background-color: var(--lightblue);
  color: #000000;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.button {
  padding: 2% 5%;
  border-radius: 5px;
  background-color: var(--darkblue);
  border: none;
}

.popup {
  display: flex;
  flex-direction: column;
  background-color: var(--text-color-dark-theme);
  width: 70vw;
  border: #261f8b 3px solid;
  min-height: 24vh;
  border-radius: 8px;
  position: fixed;
  top: 16%;
  left: 10%;
  text-align: center;
  padding: 5%;
  animation: fadeIn 0.2s;
  z-index: 10;
}

.popup h2 {
  font-size: 30px;
  margin-bottom: 15%;
  margin-top: 8%;
}

.popup p {
  margin: 0;
  color: var(--text-color);
  padding: 3%;
  margin-left: auto;
  margin-right: auto;
}

.popup button p {
  color: var(--text-color-dark-theme);
}

.popup div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  justify-content: space-around;
  margin: 10% auto 2% auto;
}

.popup .inputBox {
  text-align: left;
  font-family: Readex;
  width: 80%;
  height: 8vw;
  font-size: 17px;
  border-radius: 5px;
  border-width: 0px;
  margin: 0 auto;
  background-color: rgb(135, 206, 235, 0.3);
}

.popup button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--text-color-dark-theme);
  margin-bottom: 4%;
  margin-top: 4%;
  width: 80%;
  background-color: var(--darkblue);
  padding: 3%;
}

.popup .cross {
  width: 2rem;
  position: absolute;
  top: 3%;
  right: 5%;
  padding: 0 0 5% 5%;
}

button.danger {
  background-color: var(--danger-color) !important;
}

p.danger {
  color: var(--danger-color) !important;
}

@keyframes fadeIn {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(0px);
  }
}

/* Temporary */
@media screen and (min-width: 481px) {
  body {
    overflow: hidden;
  }
}
