.loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.loginSection h1 {
  margin-top: 20%;
  font-family: Oswald;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.loginForm a {
  color: var(--text-color);
}

.loginForm button {
  background-color: var(--darkblue);
  padding: 3%;
  margin: 1%;
  border-radius: 5px;
  border: none;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 8%;
  margin-top: 10%;
  width: 60%;
  color: var(--text-color-dark-theme);
}

.loginForm .inputBoxLogin {
  text-align: left;
  font-family: Readex;
  width: 80%;
  height: 10vw;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border-color: none;
  border-width: 0px;
  background-color: rgb(135, 206, 235, 0.3);
}

.pTags {
  font-family: Readex;
  margin-top: 20%;
}

#loginError {
  width: 60%;
  text-align: center;
}

.loading {
  margin-top: 15%;
}

@media only screen and (min-width: 992px) {
  .loginSection h1 {
    margin-top: 5%;
  }

  .labels {
    margin: 2% !important;
  }

  .loginForm .inputBoxLogin {
    width: 50%;
    height: 3vw;
  }

  .loginForm {
    margin: 2%;
  }

  .loginForm button {
    padding: 2%;
    margin: 1%;
    margin-bottom: 0%;
    margin-top: 5%;
    width: 20%;
  }

  .loading {
    margin-top: 5%;
  }
}
