.frontpage .profileFront {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontpage .profileIcon {
  background-color: var(--lightblue);
  padding: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 17px;
}

.frontpage .profileName {
  margin-left: 0.5rem;
  font-size: 17px;
}

.frontpage .hr {
  border: none;
  height: 1vw;
  width: 80vw;
  border-radius: 10px;
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 0;
}

.frontpage .hrList {
  border: none;
  border-top: .18rem solid #000000;
  width: 80vw;
  border-radius: 10px;
  margin-top: 0.5rem;
  line-height: 0;
}

.frontpage .sharedListIcon {
  width: 1.5rem;
  margin-left: 1rem;
}

.frontpage .listCategory {
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-left: 12%;
  display: inline;
}

.frontpage section {
  margin-bottom: 15%;
  margin-top: 5%;
}

.frontpage .listIcon {
  width: 1.5rem;
  margin-right: 1rem;
}

.frontpage .deleteIcon {
  width: 1.3rem;
  margin-left: auto;
  margin-right: 4rem;
}

.frontpage .list {
  display: flex;
  margin: 1.5rem auto;
}

.frontpage .listLink {
  display: flex;
  align-items: center;
  margin-left: 17%;
  text-decoration: none;
  /* color: var(--text-color); */
}

.frontpage .list p {
  font-size: 17px;
  margin: 0 auto;
}

.frontpage .list .sharedContainer p {
  font-size: 17px;
  margin: 0 auto;
}

.frontpage .sharedBy {
  font-size: 12px;
}

#deleteBtn {
  background-color: var(--danger-color);
}



.frontpage .addSection p {
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin: 3%;
}
