@import url("../../assets/css/switch.css");

.header {
  background-color: var(--lightblue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  width: 3.5rem;
  margin-left: 1.2rem;
  margin-top: 1rem;
  margin-bottom: .8rem;
}

.header a {
  background-color: transparent !important;
}

.header .headerIcons {
  display: flex;
  justify-content: end;
}

.header .headerIcons .notificationContainer {
  position: relative;
}

.header .headerIcons .iconContainer {
  width: 2.5rem;
  margin-right: .8rem;
  position: relative;
  display: flex;
  align-items: center;
}

.header .headerIcons .iconContainer .icon {
  width: 100%;
}
.header .headerIcons .iconContainer .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #d9534f;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  line-height: 13px;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.settings .pwaBtn {
  margin-right: 0 !important;
  height: unset;
}

.settings .version {
  margin-top: 0;
}

.settings div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings .darkModeToggleContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto 0;
  /* max-width: var(--max-width); */
}

#deleteProfile {
  background-color: transparent;
  padding: 0;
  width: unset;
  margin-top: 20px;
  margin-bottom: 0px;
}

.deleteProfile div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 5%;
}

.notifications {
  position: fixed;
  background-color: white;
  max-height: 60vh;
  width: 50vw;
  top: 55px;
  right: 69px;
  border-radius: 5px;
  border: black 1px solid;
  overflow-y: auto;
}

.notifications h2 {
  text-align: center;
}

.notification {
  background-color: white;
  min-height: 10vh;
  border-bottom: black 1px solid;
  padding: 0 5px 5px 5px;
}

.notification h3 {
  margin: 5px 5px 5px 0;
  display: inline-block;
}

.notification p {
  margin: 0;
}

.notification img {
  width: 18px;
}

.notification .item {
  display: flex;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 0;
  gap: 10px;
}

.notification .created_date {
  opacity: 0.5;
  font-size: 0.8rem;
}

.notification .statusDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.notification .backgroundSuccess {
  background-color: #5cb85c;
}
.notification .backgroundInfo {
  background-color: #428bca;
}
.notification .backgroundDelete {
  background-color: #d9534f;
}

.notifications .emptyMessage {
  text-align: center;
}

.notifications .acceptBtn {
  margin: 10px 0 5px 0;
  color: var(--text-color-dark-theme);
  text-transform: uppercase;
  width: 80%;
  padding-top: 8%;
  padding-bottom: 8%;
  font-size: 1.2rem;
  font-family: Oswald;
}
.notifications .invitationInfo {
  margin: 10px 0 5px 0;
}

.profile .infoContainer {
  margin-bottom: 15px;
  gap: 5px;
}

.profile .infoContainer p:first-of-type {
  font-weight: 600;
}
