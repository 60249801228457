.pwaBtn {
  background-color: white;
  margin-right: 3%;
  border-radius: 5px;
  border: none;
  font-family: Readex;
  font-size: 1rem;
  width: 8rem;
  height: 2.5rem;
}

.hideWeb {
  display: none;
}

.addSection {
  display: flex;
  background-color: var(--darkblue);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 5px;
  position: fixed;
  top: 90%;
  right: 2rem;
}

.addNewListIcon {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  filter: invert(99%) sepia(72%) saturate(6998%) hue-rotate(183deg)
    brightness(123%) contrast(100%);
}

@media screen and (min-width: 481px) {
  .hideWeb {
    height: 100%;
    width: 100%;
    background-color: var(--lightblue);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .hideWeb img {
    width: 50%;
    height: auto;
    margin-bottom: 5rem;
  }
}
