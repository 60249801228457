.signUpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.signUpSection h1 {
  margin-top: 20%;
  font-family: Oswald;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.labels {
  font-size: 1.25rem;
  margin: 8% 5% 2% 5%;
}

.signUpForm a {
  color: var(--text-color);
  font-family: Readex;
  margin-left: 0.2rem;
}

.signUpForm button,
.signUpForm #backBtn {
  margin: 1%;
  border-radius: 5px;
  border: none;
  margin-top: 15%;
  
}

.signUpForm .inputBoxSignUp {
  text-align: left;
  font-family: Readex;
  width: 80%;
  height: 10vw;
  font-size: 1.25rem;
  border-radius: 5px;
  border-color: none;
  border-width: 0px;
  background-color: rgb(135, 206, 235, 0.3);
}

#checkboxContainer {
  display: flex;
  margin-top: 5%;
  align-items: center;
}

#checkbox {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 5px;
  border: .12rem solid rgb(24, 24, 24);
  position: relative;
}

#checkbox:checked {
  background: var(--darkblue);
}

#checkbox:checked:after {
  content: "✔";
  color: white;
  font-size: 120%;
  margin: 0 auto;
  position: absolute;
  left: 20%;
}

#btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

#btnContainer button {
  background-color: var(--darkblue);
  padding: 3%;
  width: 30%;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--text-color-dark-theme);
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  .signUpSection h1 {
    margin-top: 5%;
  }

  .signUpForm {
    margin: 2%;
  }

  .signUpForm .inputBoxSignUp {
    width: 50%;
    height: 3vw;
  }

  #checkboxContainer {
    font-size: 20px;
    margin-top: 5%;
  }

  #btnContainer button {
    width: 15%;
    height: 4vw;
    padding: 2%;
    margin-top: 5%;
  }
}
