.listSection .firstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 12%;
  margin-right: 12%;
}

.listSection .firstSection #back {
  width: 2rem;
  margin-right: 10%;
}

.listSection .firstSection #share {
  width: 20px;
  margin-left: 10%;
}

.listSection .firstSection .backSection {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: var(--text-color);
  align-items: center;
}

.listSection .firstSection .shareSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 27%;
}

.listSection .hr {
  background-color: #000000;
  border: none;
  /* height: 3px; */
  border-top: .18rem solid #000000;
  width: 80%;
  border-radius: 10px;
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 0;
}

.listSection h1 {
  text-align: center;
}

.listSection .optionsIcon {
  width: 20px;
}

.todoProduct #notDoneIcon {
  width: 1.7rem;
  /* margin-right: 15px; */
}

.todoProduct #editIcon {
  width: 25px;
  margin-right: 15px;
}

.listSection .todoProduct,
.todoProduct {
  display: flex;
  justify-content: space-between;
  background-color: rgb(135, 206, 235, 0.8);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  padding: 3% 5%;
  /* transition: all 2s; */
}

.todoProduct p {
  font-size: 20px;
  margin: 0;
}

.todoProduct .doneOrNot {
  display: flex;
  align-items: center;
}

.tasks {
  width: 80%;
  margin: 0 auto 10rem auto;
}

.doneText {
  margin-left: auto;
  margin-right: auto;
}

.doneProduct {
  display: flex;
  background-color: rgb(135, 206, 235, 0.3);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  padding: 3% 5%;
  /* text-align: left; */

  justify-content: space-between;
}

.doneProduct p {
  font-size: 20px;
  margin: 0;
}

#doneIcon {
  width: 1.7rem;
  /* margin-right: 15px; */
}

/* .listSection .addSection {
  display: flex;
  background-color: var(--darkblue);
  width: 70%;
  border-radius: 5px;
  padding: 2% 5%;
  position: fixed;
  top: 90%;
  left: 10%;
} */

/* .listSection .addSection img {
  width: 35px;
  margin-right: 10px;
} */

.listSection .addSection p {
  color: var(--text-color-dark-theme);
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin: 5%;
}

.joinListResponse {
  text-align: center;
  font-size: 2rem;
  margin-top: 10rem;
}

.joinListResponse .success {
  color: var(--darkblue);
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.joinListResponse .warning {
  color: var(--darkblue);
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;

}

.joinListResponse .error {
  color: var(--darkblue);
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;

}

.joinListResponse button {
  font-size: 1.3rem;
  margin-top: 5rem;
  padding: 5% 10%;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--text-color-dark-theme);
}

.options .icon {
  width: 15px;
  margin-left: 5%;
}

.addTask p {
  margin-top: 5%;
}

.members .member p {
  font-size: 20px;
}

.members .member span {
  font-size: 16px;
}

.options button {
  justify-content: unset !important;
  gap: 10px;
}

.options.popup h2 {
  margin-bottom: 0%;
}