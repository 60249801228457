#privacyPolicyDiv {
    margin: 5%;
}

#privacyPolicyDiv h1 {
    font-size: 30px;
}

#privacyPolicyDiv h2 {
    margin-top: 15%;
}

#privacyPolicyDiv h3 {
    font-weight: 100;
}


#privacyPolicyDiv p {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

@media only screen and (min-width: 992px) {
    #privacyPolicyDiv h2 {
        margin-top: 5%;
    }
}